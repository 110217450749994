<ng-select [(ngModel)]="selectedOption"
           [ngClass]="'inline-edit--field -multi-select'"
           [required]="required"
           [clearable]="!required"
           [disabled]="inFlight"
           [id]="handler.htmlId"
           [items]="availableOptions"
           bindLabel="name"
           [virtualScroll]="true"
           [clearSearchOnAdd]="true"
           (keydown)="handler.handleUserKeydown($event, true)"
           (open)="onOpen()"
           (close)="onClose()"
           (add)="repositionDropdown()"
           (remove)="repositionDropdown()"
           [multiple]="true"
           [closeOnSelect]="false"
           [appendTo]="appendTo"
           [groupBy]="groupByFn"
           [dropdownPosition]="'top'"
           [hideSelected]="true">
  <ng-template ng-option-tmp let-item="item">
    <span
      class="ng-option-label ellipsis"
      [attr.data-hover-card-trigger-target]="getHoverCardTriggerTarget()"
      [attr.data-hover-card-url]="getHoverCardUrl(item)"
      [textContent]="item.name"
      [ngStyle]="{'padding-left.px': item.depth * 16}"
    ></span>
  </ng-template>
  <ng-template ng-footer-tmp *ngIf="showAddNewUserButton">
    <op-invite-user-button
        [projectId]="resource.project?.id"
        (invited)="selectedOption = $event"
    ></op-invite-user-button>
  </ng-template>
</ng-select>

<edit-field-controls [fieldController]="self"
                     *ngIf="!handler.inEditMode"
                     (onSave)="handler.handleUserSubmit()"
                     (onCancel)="handler.handleUserCancel()"
                     [saveTitle]="text.save"
                     [cancelTitle]="text.cancel">
</edit-field-controls>
