<div class="op-ckeditor--wrapper">
  <op-ckeditor
    [context]="context"
    [content]="initialContent"
    (initializeDone)="setup($event)"
    (contentChanged)="syncToTextarea()"
    (saveRequested)="saveForm()"
    (editorKeyup)="editorKeyup.emit()"
    (editorBlur)="editorBlur.emit()"
    (editorFocus)="editorFocus.emit()"
  ></op-ckeditor>
</div>

<fieldset
  class="form--fieldset op-ckeditor--attachments"
  *ngIf="showAttachments && halResource?.attachments"
>
  <legend class="form--fieldset-legend">
    {{ text.attachments }}
  </legend>
  <op-attachments
    [resource]="halResource"
    [destroyImmediately]="false"
  ></op-attachments>
</fieldset>
