<op-user-autocompleter
  [formControl]="formControl"
  [formlyAttributes]="field"
  [attr.aria-required]="to.required"
  [attr.required]="to.required"
  [url]="to.allowedValuesHref"
  [multiple]="to.multiple"
  [inviteUserToProject]="projectId"
>
</op-user-autocompleter>
