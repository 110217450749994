<div class="op-draggable-autocomplete">
  <div class="FormControl-spacingWrapper mb-3">
    <div class="FormControl width-full op-draggable-autocomplete-container" [id]="formControlId">
      <label class="FormControl-label"
             [textContent]="inputLabel"
             [attr.for]="id">
      </label>

      <ng-select
        #ngSelectComponent
        [items]="availableOptions"
        ngClass="op-draggable-autocomplete--input ng-select--primerized"
        bindLabel="name"
        [multiple]="false"
        [virtualScroll]="true"
        [placeholder]="inputPlaceholder"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="true"
        [searchFn]="searchFunction"
        [appendTo]="appendTo"
        (open)="opened()"
        (change)="select($event)"
      ></ng-select>
      <span
        *ngIf="inputCaption"
        class="FormControl-caption" [textContent]="inputCaption"></span>
    </div>
  </div>

  <div class="FormControl-spacingWrapper">
    <div class="FormControl width-full">
      <label class="FormControl-label"
             [textContent]="dragAreaLabel">
      </label>

      <div
        class="op-draggable-autocomplete--selected"
        dragula="columns"
        [(dragulaModel)]="selectedOptions"
      >
        <div class="op-draggable-autocomplete--item" *ngFor="let item of selectedOptions">
          <span
            class="op-draggable-autocomplete--item-text"
            [textContent]="item.name"
          ></span>
          <a
            *ngIf="isRemovable(item)"
            (click)="remove(item)"
            class="op-draggable-autocomplete--remove-item icon-remove"
          ></a>
        </div>

        <span *ngIf="!isArrayOfValues">
          <input type="hidden" [name]="name" [value]="hiddenValue" />
        </span>
        <span *ngIf="isArrayOfValues">
          <input *ngFor="let value of hiddenValues" type="hidden" [name]="name" [value]="value" />
        </span>
      </div>
      <div *ngIf="hasError" class="FormControl-inlineValidation" id="validation-1b0e1d91-109a-429f-bf04-0231cc9234ec">
        <span class=" FormControl-inlineValidation--visual" data-target="primer-text-field.validationErrorIcon">
          <svg aria-hidden="true" height="12" viewBox="0 0 12 12" version="1.1" width="12" class="octicon octicon-alert-fill">
            <path d="M4.855.708c.5-.896 1.79-.896 2.29 0l4.675 8.351a1.312 1.312 0 0 1-1.146 1.954H1.33A1.313 1.313 0 0 1 .183 9.058ZM7 7V3H5v4Zm-1 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path></svg>
          </span>
        <span [textContent]="errorMessage"></span>
      </div>
    </div>
  </div>
</div>
