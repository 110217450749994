<button
  class="button toolbar-icon"
  data-test-selector="op-wp-share-button"
  [title]="text.share"
  (click)="openModal()"
>
  <svg
    share-android-icon
    class="button--icon"
    size="small"
  ></svg>
  <span class="button--text" *ngIf="(shareCount$ | async) as shareCount">
    <span class="badge -secondary"
          [textContent]="shareCount"></span>
  </span>

  <svg
    *ngIf="showEnterpriseIcon"
    op-enterprise-addons-icon
    class="button--icon upsale-colored"
    size="small"
  ></svg>

</button>
