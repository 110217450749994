<input
  *ngIf="!mobile"
  #input
  type="text"
  autocomplete="off"
  class="spot-input op-basic-range-datepicker--input"
  data-test-selector="op-basic-range-date-picker"
  [ngClass]="inputClassNames"
  [attr.data-value]="value"
  [attr.data-action]="dataAction"
  [id]="id"
  [name]="name"
  [attr.name]="name"
  [required]="required"
  [disabled]="disabled"
  [ngModel]="stringValue"
  (ngModelChange)="changeValueFromInputDebounced($event)"
  (focus)="showDatePicker()"
  (click)="sentCalendarToTopLayer()"
/>
<ng-container *ngIf="mobile">
  <input
    type="date"
    class="spot-input"
    data-test-selector="op-basic-range-date-picker-start"
    [ngClass]="inputClassNames"
    id="{{ id }}-start"
    name="{{ name }}-start"
    [required]="required"
    [disabled]="disabled"
    [ngModel]="value[0] || ''"
    (ngModelChange)="changeValueFromInputDebounced([$event, value[1] || ''])"
  />
  <span class="op-basic-range-datepicker--spacer" [textContent]="text.spacer"></span>
  <input
    type="date"
    class="spot-input"
    data-test-selector="op-basic-range-date-picker-end"
    [ngClass]="inputClassNames"
    id="{{ id }}-end"
    name="{{ name }}-end"
    [required]="required"
    [disabled]="disabled"
    [ngModel]="value[1] || ''"
    (ngModelChange)="changeValueFromInputDebounced([value[0] || '', $event])"
  />
  <input
    type="hidden"
    data-test-selector="op-basic-range-date-picker--value"
    [attr.data-value]="stringValue"
    [id]="id"
    [name]="name"
    [attr.name]="name"
    [attr.data-action]="dataAction"
    [ngModel]="stringValue"
  />
</ng-container>
