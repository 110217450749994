<div id="work-package-activites-container">
  <turbo-frame [src]="turboFrameSrc" id="work-package-activities-tab-content" loading="lazy" #activitiesTabContent>
    <op-content-loader viewBox="0 0 100 100">
      <svg:rect x="0" y="0" width="70" height="5" rx="1" />
  
      <svg:rect x="75" y="0" width="25" height="5" rx="1" />
  
      <svg:rect x="0" y="10" width="100" height="8" rx="1" />
  
      <svg:rect x="0" y="25" width="100" height="12" rx="1" />
    </op-content-loader>
  </turbo-frame>
</div>
