<button
  (click)="markAllBelongingNotificationsAsRead()"
  [attr.title]="text.mark_as_read"
  class="button">

  <svg
    read-icon
    class="button--icon"
    size="small"
  ></svg>
</button>
